import PropTypes from 'prop-types'
import React from 'react'

const DigimedSuggestion = ({ heading, imageUrl }) => (
  <a
    href="https://hjemmelegene.no/mobil"
    className="info-box mt-2 no-link-decoration"
    data-testid="digimed-suggestion"
  >
    <img
      src={imageUrl}
      width="20%"
      style={{
        float: 'right',
        margin: '0 0 1rem 1rem',
      }}
    />
    <h2 className="h4">{heading}</h2>
    <p>
      Snakk med en erfaren Hjemmelege på chat, video eller telefon nå.
      Våre leger kan hjelpe med behandlingsråd, resepter og
      legeerklæringer. Tjenesten er åpen 07–22 alle dager og koster kr
      359.
    </p>
    <button className="btn btn-primary">Snakk med lege nå</button>
  </a>
)

DigimedSuggestion.propTypes = {
  heading: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
}

export default DigimedSuggestion
