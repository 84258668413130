import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Input from '../inputs/Input'

class DiscountInput extends Component {
  static propTypes = {
    updatePrice: PropTypes.func.isRequired,
    type: PropTypes.string.isRequired,
    zip: PropTypes.string.isRequired,
    initialValue: PropTypes.string,
  }

  constructor(props) {
    super(props)

    this.state = {
      couponCode: props.initialValue || '',
      discountedPrice: null,
      couponError: false,
      infoMessage: null,
      infoMessageCheckBox: false,
    }
  }

  componentDidMount() {
    if (this.state.couponCode.length > 0) {
      this.checkCouponCode()
    }
  }

  handleCouponCodeChange = (couponCode) => {
    this.props.updatePrice(null, null)
    this.setState({
      couponCode,
      infoMessage: null,
      infoMessageCheckBox: false,
      couponError: false,
      discountedPrice: null,
    })
  }

  checkCouponCode = (e, callback) => {
    if (e) {
      e.preventDefault()
    }

    dataLayer.push({
      event: 'GAEvent',
      eventCategory: 'discount_code',
      eventAction: 'add',
      eventLabel: this.state.couponCode,
      eventValue: 5,
      nonInteraction: false,
    })

    let infoMessage = null
    let infoMessageCheckBox = false
    if (/^snørrogtårer$/i.test(this.state.couponCode.trim())) {
      infoMessage =
        'Pasienten er 12 år eller yngre (behandleren vil bekrefte dette ved hjemmebesøket)'
      infoMessageCheckBox = true
    }

    $.get('/coupons/check', {
      code: this.state.couponCode,
      zip: this.props.zip || '',
      type: this.props.type,
    })
      .done((coupon) => {
        dataLayer.push({
          event: 'GAEvent',
          eventCategory: 'discount_code',
          eventAction: 'add',
          eventLabel: this.state.couponCode,
          // eventValue: EVENT_VALUE,
          // nonInteraction: false,
        })
        this.props.updatePrice(
          coupon.discounted_price_raw,
          this.state.couponCode,
          coupon.amount,
        )
        this.setState({
          discountedPrice: coupon.discounted_price_raw,
          couponError: false,
          error: null,
          infoMessage,
          infoMessageCheckBox,
        })
      })
      .fail((response) => {
        dataLayer.push({
          event: 'GAEvent',
          eventCategory: 'discount_code',
          eventAction: 'error',
          eventLabel: this.state.couponCode,
          // eventValue: EVENT_VALUE,
          // nonInteraction: false,
        })
        if (response.status == 410) {
          infoMessage =
            'Beklager, kampanjen er utgått for denne gang. Håper allikevel du vil gi oss et forsøk 🙂'
          infoMessageCheckBox = false
        }

        if (response.status == 406) {
          infoMessage =
            'Beklager, rabattkoden gjelder ikke for denne tjenesten eller er allerede brukt'
          infoMessageCheckBox = false
        }

        if (response.status == 404) {
          infoMessage = 'Ugyldig rabattkode'
          infoMessageCheckBox = false
        }

        this.props.updatePrice(null, null)
        this.setState({
          discountedPrice: null,
          couponError: true,
          infoMessage,
          infoMessageCheckBox,
        })
      })
      .always(callback)
  }

  couponCodeIsInvalid() {
    return (
      this.state.couponCode.length > 0 &&
      this.state.discountedPrice === null
    )
  }

  couponCodeIsValid() {
    return (
      this.state.couponCode.length > 0 &&
      this.state.discountedPrice !== null
    )
  }

  renderInfoMessage() {
    if (
      this.state.infoMessage != null &&
      this.state.infoMessageCheckBox
    ) {
      return (
        <div className="mt-1">
          <label className="custom-control custom-checkbox">
            <input className="custom-control-input" type="checkbox" />
            <span className="custom-control-indicator"></span>
            <span className="custom-control-description">
              Pasienten er 12 år eller yngre (behandleren vil bekrefte
              dette ved hjemmebesøket)
            </span>
          </label>
        </div>
      )
    } else if (this.state.infoMessage != null) {
      return (
        <div className="small-text mt-1">
          {this.state.infoMessage}
        </div>
      )
    }
  }

  couponInputClass = () => {
    if (this.couponCodeIsValid()) {
      return 'form-control input-with-button__input payment-form__coupon--valid'
    }

    return 'form-control input-with-button__input'
  }

  renderCouponCodeButton() {
    if (this.couponCodeIsValid()) {
      return (
        <button
          className="input-with-button__button input-with-button__button--disabled"
          onClick={this.checkCouponCode}
          disabled={true}
        >
          Lagt til!
        </button>
      )
    } else if (this.state.couponError) {
      return (
        <button
          className="input-with-button__button booking-form__btn--danger"
          onClick={this.checkCouponCode}
        >
          Feil kode
        </button>
      )
    } else if (this.state.couponCode.length < 1) {
      return (
        <button
          className="input-with-button__button input-with-button__button--disabled"
          onClick={this.checkCouponCode}
          disabled={true}
        >
          Legg til
        </button>
      )
    } else {
      return (
        <button
          className="input-with-button__button"
          onClick={this.checkCouponCode}
        >
          Legg til
        </button>
      )
    }
  }

  render() {
    return (
      <div>
        <div className="input-with-button">
          <Input
            id="couponcode"
            name="booking[coupon_code]"
            placeholder="Rabattkode?"
            autoComplete="off"
            className={this.couponInputClass()}
            value={this.state.couponCode}
            onChangeText={this.handleCouponCodeChange}
            maxLength={15}
          />

          {this.renderCouponCodeButton()}
        </div>
        {this.renderInfoMessage()}
      </div>
    )
  }
}

export default DiscountInput
